// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-finding-true-wealth-js": () => import("./../../../src/pages/finding-true-wealth.js" /* webpackChunkName: "component---src-pages-finding-true-wealth-js" */),
  "component---src-pages-firms-cutter-co-financial-planning-js": () => import("./../../../src/pages/firms/cutter-co-financial-planning.js" /* webpackChunkName: "component---src-pages-firms-cutter-co-financial-planning-js" */),
  "component---src-pages-firms-destination-financial-planning-js": () => import("./../../../src/pages/firms/destination-financial-planning.js" /* webpackChunkName: "component---src-pages-firms-destination-financial-planning-js" */),
  "component---src-pages-firms-flowers-mcewan-js": () => import("./../../../src/pages/firms/flowers-mcewan.js" /* webpackChunkName: "component---src-pages-firms-flowers-mcewan-js" */),
  "component---src-pages-firms-law-society-ni-financial-advice-js": () => import("./../../../src/pages/firms/law-society-ni-financial-advice.js" /* webpackChunkName: "component---src-pages-firms-law-society-ni-financial-advice-js" */),
  "component---src-pages-firms-lee-strathy-js": () => import("./../../../src/pages/firms/lee-strathy.js" /* webpackChunkName: "component---src-pages-firms-lee-strathy-js" */),
  "component---src-pages-firms-navigator-financial-planning-js": () => import("./../../../src/pages/firms/navigator-financial-planning.js" /* webpackChunkName: "component---src-pages-firms-navigator-financial-planning-js" */),
  "component---src-pages-firms-tag-financial-planning-js": () => import("./../../../src/pages/firms/tag-financial-planning.js" /* webpackChunkName: "component---src-pages-firms-tag-financial-planning-js" */),
  "component---src-pages-firms-true-wealth-management-js": () => import("./../../../src/pages/firms/true-wealth-management.js" /* webpackChunkName: "component---src-pages-firms-true-wealth-management-js" */),
  "component---src-pages-firms-veracity-ats-js": () => import("./../../../src/pages/firms/veracity-ats.js" /* webpackChunkName: "component---src-pages-firms-veracity-ats-js" */),
  "component---src-pages-firms-watson-laird-js": () => import("./../../../src/pages/firms/watson-laird.js" /* webpackChunkName: "component---src-pages-firms-watson-laird-js" */),
  "component---src-pages-growth-strategy-js": () => import("./../../../src/pages/growth-strategy.js" /* webpackChunkName: "component---src-pages-growth-strategy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-purpose-js": () => import("./../../../src/pages/our-purpose.js" /* webpackChunkName: "component---src-pages-our-purpose-js" */),
  "component---src-pages-truewealthgroup-timeline-js": () => import("./../../../src/pages/truewealthgroup-timeline.js" /* webpackChunkName: "component---src-pages-truewealthgroup-timeline-js" */)
}

